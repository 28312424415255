import React, { useState } from 'react';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';

import { submitRegister } from 'app/auth/store/registerSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { checkPhoneNumber } from 'shared/util/checkInput';
import { sendVerificationCode, exisitPhoneNumber } from './actions';

export default function EmailRegister() {
	const dispatch = useDispatch();
	const { form, handleChange, resetForm } = useForm({
		name: '',
		phone: '',
		password: '',
		passwordConfirm: '',
		code: '',
		acceptTermsConditions: true
	});

	const [cooldown, setCoolDown] = useState(0);

	const handleSentVeificationCode = async () => {
		if (!checkPhoneNumber(form.phone)) {
			dispatch(showMessage({ message: '手机号格式不正确' }));
			return false;
		}

		try {
			const exist = await exisitPhoneNumber(form.phone);
			if (exist) {
				dispatch(showMessage({ message: '该手机账号已注册！' }));
				return false;
			}
		} catch (error) {
			dispatch(showMessage({ message: '无法验证手机' }));
		}

		try {
			const code = await sendVerificationCode(form.phone);
			dispatch(showMessage({ message: '验证码已发送，请注意查收！' }));
			localStorage.setItem(form.phone, code);
			localStorage.setItem('expire', Math.floor(Date.now()) + 5 * 60 * 1000);
			setCoolDown(30);
			setInterval(() => {
				setCoolDown(prev => prev - 1);
			}, 1000);
		} catch (error) {
			dispatch(showMessage({ message: '验证码发送失败！' }));
		}
	};

	const isFormValid = () => {
		if (!checkPhoneNumber(form.phone)) {
			dispatch(showMessage({ message: '手机号格式不正确' }));
			return false;
		}
		if (!localStorage.getItem('expire') || Math.floor(Date.now()) > localStorage.getItem('expire')) {
			localStorage.clear();
			dispatch(showMessage({ message: '验证码已失效' }));
			return false;
		}
		if (!localStorage.getItem(form.phone) || form.code !== localStorage.getItem(form.phone)) {
			dispatch(showMessage({ message: '手机验证码错误' }));
			return false;
		}
		if (form.password !== form.passwordConfirm) {
			dispatch(showMessage({ message: '两次输入密码不一致！' }));
			return false;
		}
		if (!form.acceptTermsConditions) {
			dispatch(showMessage({ message: '请阅读并同意注册条款！' }));
			return false;
		}
		return true;
	};

	const handleSubmit = async ev => {
		ev.preventDefault();
		if (!isFormValid()) return;
		dispatch(submitRegister({ name: form.name, password: form.password, email: form.phone }));
		resetForm();
	};

	return (
		<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
			<TextField
				className="mb-16"
				label="姓名"
				autoFocus
				type="name"
				name="name"
				value={form.name}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>

			<TextField
				className="mb-16"
				label="手机号"
				type="tel"
				name="phone"
				value={form.phone}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>
			<div className="flex justify-between mb-16">
				<TextField
					label="验证码"
					name="code"
					value={form.code}
					onChange={handleChange}
					variant="outlined"
					required
				/>
				{cooldown > 0 ? (
					<Button color="primary" variant="outlined" disabled>
						{cooldown}秒后获取
					</Button>
				) : (
					<Button color="primary" variant="outlined" onClick={handleSentVeificationCode}>
						获取验证码
					</Button>
				)}
			</div>

			<TextField
				className="mb-16"
				label="Password"
				type="password"
				name="password"
				value={form.password}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
				inputProps={{ minLength: 4 }}
			/>

			<TextField
				className="mb-16"
				label="Password (Confirm)"
				type="password"
				name="passwordConfirm"
				value={form.passwordConfirm}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>

			<FormControl className="items-center">
				<FormControlLabel
					control={
						<Checkbox
							name="acceptTermsConditions"
							checked={form.acceptTermsConditions}
							onChange={handleChange}
						/>
					}
					label="我已阅读并同意注册条款"
				/>
			</FormControl>

			<Button variant="contained" color="primary" className="w-full mx-auto mt-16" type="submit">
				创建账号
			</Button>
		</form>
	);
}
