import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PhoneResetPassword from './PhoneResetPassword';
import EmailResetPassword from './EmailResetPassword';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default function ForgetPassword(props) {
	const [tab, setTab] = useState(0);

	return (
		<Dialog disableBackdropClick fullWidth open={props.open} onClose={props.handleClose}>
			<DialogTitle onClose={props.handleClose}>忘记密码</DialogTitle>
			<DialogContent className="pb-20">
				<Paper className="my-20">
					<Tabs
						value={tab}
						onChange={(event, newValue) => {
							setTab(newValue);
						}}
						indicatorColor="primary"
						textColor="primary"
						centered
					>
						<Tab label="手机重置" id="simple-tab-0" />
						<Tab label="邮箱重置" id="simple-tab-1" />
					</Tabs>
				</Paper>
				{tab === 0 ? (
					<PhoneResetPassword handleClose={props.handleClose} />
				) : (
					<EmailResetPassword handleClose={props.handleClose} />
				)}
			</DialogContent>
		</Dialog>
	);
}
