import React from 'react';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';

import { submitRegister } from 'app/auth/store/registerSlice';
import { showMessage } from 'app/store/fuse/messageSlice';

export default function EmailRegister() {
	const dispatch = useDispatch();
	const { form, handleChange, resetForm } = useForm({
		name: '',
		email: '',
		password: '',
		passwordConfirm: '',
		acceptTermsConditions: true
	});

	const isFormValid = () => {
		if (form.password !== form.passwordConfirm) {
			dispatch(showMessage({ message: '两次输入密码不一致！' }));
			return false;
		}
		if (!form.acceptTermsConditions) {
			dispatch(showMessage({ message: '请阅读并同意注册条款！' }));
			return false;
		}
		return true;
	};

	const handleSubmit = async ev => {
		ev.preventDefault();
		if (!isFormValid()) {
			return;
		}
		dispatch(submitRegister(form));
		resetForm();
	};

	return (
		<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
			<TextField
				className="mb-16"
				label="姓名"
				autoFocus
				type="name"
				name="name"
				value={form.name}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>

			<TextField
				className="mb-16"
				label="邮箱"
				type="email"
				name="email"
				value={form.email}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>

			<TextField
				className="mb-16"
				label="Password"
				type="password"
				name="password"
				value={form.password}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
				inputProps={{ minLength: 4 }}
			/>

			<TextField
				className="mb-16"
				label="Password (Confirm)"
				type="password"
				name="passwordConfirm"
				value={form.passwordConfirm}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>

			<FormControl className="items-center">
				<FormControlLabel
					control={
						<Checkbox
							name="acceptTermsConditions"
							checked={form.acceptTermsConditions}
							onChange={handleChange}
						/>
					}
					label="我已阅读并同意注册条款"
				/>
			</FormControl>

			<Button variant="contained" color="primary" className="w-full mx-auto mt-16" type="submit">
				创建账号
			</Button>
		</form>
	);
}
