import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { submitLogin } from '../../auth/store/loginSlice';
import { useDispatch } from 'react-redux';
import ForgetPassword from './ForgetPassword/ForgetPassword';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	image: {
		maxWidth: '100%',
		maxHeight: '100%'
	},
	red: {
		color: 'red'
	},
	link: {
		backgroundColor: 'transparent!important',
		'&:hover': {
			color: '#999!important'
		}
	}
}));

function Login2Page() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { form, handleChange, resetForm } = useForm({
		email: 'htkzmo@gmail.com',
		password: '1234',
		remember: true
	});

	const [showForgetPassword, setShowForgetPassword] = useState(false);

	function isFormValid() {
		return form.email.length > 0 && form.password.length > 0;
	}

	async function handleSubmit(ev) {
		ev.preventDefault();
		const { email, password } = form;
		dispatch(submitLogin(email, password));
		resetForm();
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0')}>
			<div
				className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:items-start md:flex-shrink-0 md:flex-1 md:text-left"
				style={{
					backgroundImage: `url('assets/images/entry/entry3.png')`,
					backgroundSize: 'cover',
					position: 'relative'
				}}
			>
				<FuseAnimate animation="transition.slideUpIn" delay={300}>
					<Typography variant="h3" color="inherit" className="font-light mt-128 mb-20">
						中国非物质文化遗产
					</Typography>
				</FuseAnimate>

				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16 mb-64">
						非物质文化遗产凝结着一个民族和地域的历史记忆、共同情感和经验智慧，是民族精神家园的重要源泉，以其为典型代表的中国优秀传统文化是中华民族共同的精神家园，是我们的根与魂。
					</Typography>
				</FuseAnimate>
				<div style={{ position: 'absolute', bottom: '0' }}>
					<div className="flex justify-center full-width">
						<Typography>
							Copyright © 2021 星多多（北京）文化创意产业有限公司. All rights reserved.
							<a href="https://beian.miit.gov.cn/" target="_blank" className={classes.link}>
								京ICP备2020039140号-1
							</a>
						</Typography>
					</div>
					<div className="mb-20">
						<a
							target="_blank"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502004690"
							className={classes.link}
						>
							<img src="assets/images/logos/record.png" className="inline" />
							<span>京公网安备 11011502004690号</span>
						</a>
					</div>
				</div>
			</div>

			<FuseAnimate animation={{ translateX: [0, '100%'] }}>
				<Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
					<CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128">
						<Typography variant="h6" align="center" className="md:w-full mb-32 border-1 border-blue py-20">
							当前已有<span className={classes.red}>24587</span>人加入课程!
						</Typography>
						<Typography variant="h6" align="center" className="md:w-full mb-32">
							用户登录
						</Typography>
						<form name="loginForm" className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
							<TextField
								className="mb-16"
								label="邮箱/手机号"
								autoFocus
								type="text"
								name="email"
								value={form.email}
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
							/>

							<TextField
								className="mb-16"
								label="密码"
								type="password"
								name="password"
								value={form.password}
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
							/>

							<div className="flex items-center justify-between">
								<FormControl>
									<FormControlLabel
										control={
											<Checkbox name="remember" checked={form.remember} onChange={handleChange} />
										}
										label="记住我"
									/>
								</FormControl>

								<Button
									className="font-medium"
									color="secondary"
									onClick={() => setShowForgetPassword(true)}
								>
									忘记密码?
								</Button>
							</div>

							<Button
								variant="contained"
								color="primary"
								className="w-full mx-auto mt-16"
								aria-label="LOG IN"
								disabled={!isFormValid()}
								type="submit"
							>
								登录
							</Button>
						</form>

						<div className="flex flex-col items-center justify-center pt-32 pb-24">
							<span className="font-medium">没有账号?</span>
							<Link className="font-medium" to="/register">
								创建账号
							</Link>
						</div>
					</CardContent>
				</Card>
			</FuseAnimate>

			<ForgetPassword open={showForgetPassword} handleClose={() => setShowForgetPassword(false)} />
		</div>
	);
}

export default Login2Page;
