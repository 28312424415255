/**
 * Authorization Roles
 */
const authRoles = {
	admin: ['admin'],
	orgAdmin: ['admin', 'orgAdmin'],
	instructor: ['admin', 'orgAdmin', 'instructor'],
	student: ['admin', 'orgAdmin', 'instructor', 'student'],
	onlyGuest: [],
	onlyInstructor: ['instructor'],
	onlyOrgAdmin: ['orgAdmin'],
	onlyStudent: ['student'],
	roleList: ['admin', 'orgAdmin', 'instructor', 'student']
};

const translate = {
	admin: '超级管理员',
	orgAdmin: '机构管理员',
	instructor: '教师',
	student: '学生'
};

export const roleToChinese = role => translate[role];

export default authRoles;
