import axios from 'axios';
import { baseUrl } from 'settings/constants';

export const sendVerificationCode = async phone => {
	const response = await axios.post(`${baseUrl}api/aliyun-verification-code`, {
		phone: phone,
		type: 'change_password'
	});
	return response.data;
};

export const sendVerificationEmail = async email => {
	const randomCode = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
	const content = `您正在使用邮箱重置密码，您的验证码为${randomCode}!`;
	await axios.post(`${baseUrl}api/aliyun-email`, {
		email: email,
		content: content,
		subject: '重置密码'
	});
	return randomCode;
};

export const exisitUserName = async userName => {
	const filter = {
		where: {
			email: userName
		}
	};
	const encodedFilter = encodeURIComponent(JSON.stringify(filter));
	const response = await axios.get(`${baseUrl}api/users?filter=${encodedFilter}`);
	return response?.data && response.data.length > 0;
};

export const changePassword = async (username, password) => {
	const response = await axios.patch(`${baseUrl}api/change-password`, {
		username,
		password
	});
	return response.data;
};
