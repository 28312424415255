import setting from '../env';

const url = {
	production: 'http://139.196.199.152:3000/',
	production_silicon: 'http://47.254.91.18:3000/',
	development: 'http://localhost:3000/'
};

export const baseUrl = url[setting.status];

export const region = 'oss-cn-shanghai';
export const bucket = 'sh-hai';
export const urlPrefix = `http://${bucket}.${region}.aliyuncs.com/`;
