import React from 'react';
import { authRoles } from 'app/auth';

const WorkAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			auth: authRoles.student,
			path: '/apps/works/myworks',
			component: React.lazy(() => import('./myWork/myWorks'))
		},
		{
			auth: authRoles.student,
			path: '/apps/works/allworks',
			component: React.lazy(() => import('./works/works'))
		}
	]
};

export default WorkAppConfig;
