import React, { useState } from 'react';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';

import { showMessage } from 'app/store/fuse/messageSlice';
import { exisitUserName, sendVerificationEmail, changePassword } from './actions';

export default function EmailResetPassword(props) {
	const dispatch = useDispatch();
	const { form, handleChange, resetForm } = useForm({
		email: '',
		password: '',
		passwordConfirm: '',
		code: ''
	});

	const [cooldown, setCoolDown] = useState(0);

	const handleSentVeificationCode = async () => {
		try {
			const exist = await exisitUserName(form.email);
			if (!exist) {
				dispatch(showMessage({ message: '该邮箱账号不存在！' }));
				return false;
			}
		} catch (error) {
			dispatch(showMessage({ message: error.message }));
		}
		try {
			setCoolDown(40);
			setInterval(() => {
				setCoolDown(prev => prev - 1);
			}, 1000);
			const code = await sendVerificationEmail(form.email);
			dispatch(showMessage({ message: '验证码已发送，请注意查收！' }));
			localStorage.setItem(form.email, code);
			localStorage.setItem('email-expire', Math.floor(Date.now()) + 5 * 60 * 1000);
		} catch (error) {
			dispatch(showMessage({ message: '验证码发送失败！' }));
		}
	};

	const isFormValid = () => {
		if (!localStorage.getItem('email-expire') || Math.floor(Date.now()) > localStorage.getItem('email-expire')) {
			localStorage.clear();
			dispatch(showMessage({ message: '验证码已失效' }));
			return false;
		}
		if (!localStorage.getItem(form.email) || form.code !== localStorage.getItem(form.email)) {
			dispatch(showMessage({ message: '邮箱验证码错误' }));
			return false;
		}
		if (form.password !== form.passwordConfirm) {
			dispatch(showMessage({ message: '两次输入密码不一致！' }));
			return false;
		}
		return true;
	};

	const handleSubmit = async ev => {
		ev.preventDefault();
		if (!isFormValid()) return;
		try {
			await changePassword(form.email, form.password);
			dispatch(showMessage({ message: '已成功重置密码' }));
			localStorage.clear();
			props.handleClose();
		} catch (error) {
			dispatch(showMessage({ message: error.message }));
		}
		resetForm();
	};

	return (
		<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
			<TextField
				className="mb-16"
				label="邮箱"
				type="email"
				name="email"
				value={form.email}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>
			<div className="flex justify-between mb-16">
				<TextField
					label="验证码"
					name="code"
					value={form.code}
					onChange={handleChange}
					variant="outlined"
					required
				/>
				{cooldown > 0 ? (
					<Button color="primary" variant="outlined" disabled>
						{cooldown}秒后获取
					</Button>
				) : (
					<Button color="primary" variant="outlined" onClick={handleSentVeificationCode}>
						获取验证码
					</Button>
				)}
			</div>

			<TextField
				className="mb-16"
				label="Password"
				type="password"
				name="password"
				value={form.password}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
				inputProps={{ minLength: 4 }}
			/>

			<TextField
				className="mb-16"
				label="Password (Confirm)"
				type="password"
				name="passwordConfirm"
				value={form.passwordConfirm}
				onChange={handleChange}
				variant="outlined"
				required
				fullWidth
			/>

			<Button variant="contained" color="primary" className="w-full mx-auto mt-16" type="submit">
				更改密码
			</Button>
		</form>
	);
}
