import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	link: {
		backgroundColor: 'transparent!important',
		'&:hover': {
			color: '#999!important'
		}
	}
}));

function FooterLayout1(props) {
	const footerTheme = useSelector(selectFooterTheme);
	const classes = useStyles();

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar id="fuse-footer" className="relative z-10" color="default" elevation={2}>
				<Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex flex-center justify-center items-center overflow-x-auto">
					<Typography>
						Copyright © 2021 星多多（北京）文化创意产业有限公司. All rights reserved.
						<Link href=" " target="_blank" className={classes.link}>
							京ICP备2020039140号-1
						</Link>
						<a
							target="_blank"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502004690"
							className={classes.link}
						>
							<img src="assets/images/logos/record.png" className="inline mx-4" />
							<span>京公网安备 11011502004690号</span>
						</a>
					</Typography>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default React.memo(FooterLayout1);
