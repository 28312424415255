import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import EmailRegister from './tabs/EmailRegister';
import PhoneRegister from './tabs/PhoneRegister';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	red: {
		color: 'red'
	},
	link: {
		backgroundColor: 'transparent!important',
		'&:hover': {
			color: '#999!important'
		}
	}
}));

function Register2Page() {
	const classes = useStyles();

	const [tab, setTab] = useState(0);

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0')}>
			<div
				className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:items-start md:flex-shrink-0 md:flex-1 md:text-left"
				style={{
					backgroundImage: `url('assets/images/entry/entry3.png')`,
					backgroundSize: 'cover',
					position: 'relative'
				}}
			>
				<FuseAnimate animation="transition.slideUpIn" delay={300}>
					<Typography variant="h3" color="inherit" className="font-light mt-128 mb-20">
						中国非物质文化遗产
					</Typography>
				</FuseAnimate>

				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16 mb-64">
						非物质文化遗产凝结着一个民族和地域的历史记忆、共同情感和经验智慧，是民族精神家园的重要源泉，以其为典型代表的中国优秀传统文化是中华民族共同的精神家园，是我们的根与魂。
					</Typography>
				</FuseAnimate>

				<div style={{ position: 'absolute', bottom: '0' }}>
					<div className="flex justify-center full-width">
						<Typography>
							Copyright © 2021 星多多（北京）文化创意产业有限公司. All rights reserved.
							<a href="https://beian.miit.gov.cn/" target="_blank" className={classes.link}>
								京ICP备2020039140号-1
							</a>
						</Typography>
					</div>
					<div className="mb-20">
						<a
							target="_blank"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502004690"
							className={classes.link}
						>
							<img src="assets/images/logos/record.png" className="inline" />
							<span>京公网安备 11011502004690号</span>
						</a>
					</div>
				</div>
			</div>

			<FuseAnimate animation={{ translateX: [0, '100%'] }}>
				<Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
					<CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-64 ">
						<Typography variant="h6" align="center" className="md:w-full mb-32 border-1 border-blue py-20">
							当前已有<span className={classes.red}>24587</span>人加入课程!
						</Typography>
						<Paper className="my-20">
							<Tabs
								value={tab}
								onChange={(event, newValue) => {
									setTab(newValue);
								}}
								indicatorColor="primary"
								textColor="primary"
								centered
							>
								<Tab label="手机注册" id="simple-tab-0" />
								<Tab label="邮箱注册" id="simple-tab-1" />
							</Tabs>
						</Paper>

						{tab === 1 ? <EmailRegister /> : <PhoneRegister />}

						<div className="flex flex-col items-center justify-center pt-32 pb-24">
							<span className="font-medium">已有账号?</span>
							<Link className="font-medium" to="/login">
								登录
							</Link>
						</div>
					</CardContent>
				</Card>
			</FuseAnimate>
		</div>
	);
}

export default Register2Page;
