import axios from 'axios';
import { baseUrl } from 'settings/constants';

export const sendVerificationCode = async phone => {
	const response = await axios.post(`${baseUrl}api/aliyun-verification-code`, {
		phone: phone,
		type: 'register'
	});
	return response.data;
};

export const exisitPhoneNumber = async phone => {
	const filter = {
		where: {
			email: phone
		}
	};
	const encodedFilter = encodeURIComponent(JSON.stringify(filter));
	const response = await axios.get(`${baseUrl}api/users?filter=${encodedFilter}`);
	return response?.data && response.data.length > 0;
};
